import { Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Colors } from '~/libs/mui/theme'

export const LoginFAQLink = () => {
  const { t } = useTranslation()

  return (
    <Stack direction='row' spacing={1.5} justifyContent='center'>
      <Typography
        component='span'
        color={Colors.colorD7D9DB}
        sx={{
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '140%',
        }}
      >
        {t('for_those_unable_to_log_in')}
      </Typography>
      <Link
        href='https://lp.samansa.com/#:~:text=%E6%95%B0%E6%9C%AC%E8%BF%BD%E5%8A%A0%EF%BC%81-,%E3%8[…]5%88%A5%E3%81%AE%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88'
        underline='none'
      >
        <Typography
          component='span'
          color={Colors.colorD7D9DB}
          sx={{
            textDecoration: 'underline',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '140%',
          }}
        >
          FAQ
        </Typography>
      </Link>
    </Stack>
  )
}
