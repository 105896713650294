import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField, type TextFieldProps } from '@mui/material'
import { FC, forwardRef, useCallback, useState } from 'react'

export const PasswordField: FC<TextFieldProps> = forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }, [])

  return (
    <TextField
      ref={ref}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              sx={{ color: 'text.primary' }}
              aria-label='toggle password visibility'
              onClick={() => setShowPassword((show) => !show)}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
})

PasswordField.displayName = 'PasswordField'
