import { TextField, type TextFieldProps } from '@mui/material'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'

type RhfTextAreaProps<T extends FieldValues> = TextFieldProps & UseControllerProps<T>
/**
 * MUIフィールドコンポーネントのreact-hook-formでラップして、controlの制御配下にする
 */
export const RhfTextField = <T extends FieldValues>(props: RhfTextAreaProps<T>) => {
  const { name, control, rules, helperText, ...restProps } = props
  const {
    field: { ref, ...restField },
    fieldState: { error },
  } = useController<T>({ name, control, rules })

  return (
    <TextField
      inputRef={ref}
      error={!!error}
      helperText={error?.message || helperText}
      {...restField}
      {...restProps}
    />
  )
}
