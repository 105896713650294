import { atom } from 'recoil'

export const verifyEmailState = atom<string>({
  key: 'verify_email',
  default: '',
})

export const verifyPasswordState = atom<string>({
  key: 'verify_password',
  default: '',
})
