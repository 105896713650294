import { Box, Typography } from '@mui/material'
import Link from 'next/link'
import { SvgAsset } from '~/components/SvgAsset'
import { Colors } from '~/libs/mui/theme'
import { PublicHeaderProps } from './PublicHeader'

export function PublicHeaderMobileContent({
  title = '',
  isDisplayBrandLogoOnMobile = false,
  mobileRightContent = undefined,
}: PublicHeaderProps): JSX.Element {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: 64,
      }}
    >
      {isDisplayBrandLogoOnMobile && (
        <Box display='flex' alignItems='center'>
          <Link href={'/'}>
            <SvgAsset asset='logo_with_text' sx={{ width: 114, height: 30 }} />
          </Link>
        </Box>
      )}
      <Typography variant='h1' color={Colors.yellow}>
        {title}
      </Typography>
      {mobileRightContent && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            right: 16,
          }}
        >
          {mobileRightContent}
        </Box>
      )}
    </Box>
  )
}
