import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  styled,
} from '@mui/material'
import { Trans } from 'react-i18next'

const CloseArea = styled(Box)(() => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}))

interface CompanyInfoModalContentProps {
  onClose: () => void
  redirectToCompanyHp: () => void
  redirectToContactForm: () => void
}

export function CompanyInfoModalContent({
  onClose,
  redirectToContactForm,
  redirectToCompanyHp,
}: CompanyInfoModalContentProps): JSX.Element {
  return (
    <Box role='presentation'>
      <List sx={{ paddingTop: 4, paddingBottom: 4 }}>
        <ListItem disablePadding>
          <ListItemButton onClick={redirectToContactForm}>
            <ListItemText
              sx={{
                textAlign: 'center',
              }}
            >
              <Trans i18nKey='if_you_have_any_issues' />
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={redirectToCompanyHp}>
            <ListItemText
              sx={{
                textAlign: 'center',
              }}
            >
              <Trans i18nKey='company_hp' />
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <CloseArea paddingTop={2.5} paddingBottom={3} textAlign='center' onClick={onClose}>
        <Typography>
          <Trans i18nKey='close' />
        </Typography>
      </CloseArea>
    </Box>
  )
}
