import { Button, Typography } from '@mui/material'
import { default as NextLink } from 'next/link'
import { Trans } from 'react-i18next'
import * as GA from '~/libs/ga'
import { Colors } from '~/libs/mui/theme'

export function ForHasNotAccountLink(): JSX.Element {
  return (
    <Button
      variant='outlined'
      LinkComponent={NextLink}
      href={'/signup'}
      sx={{
        border: '2px solid',
        borderRadius: 24,
      }}
      onClick={() => {
        GA.sendEvent({
          event: 'click',
          screen: 'login',
          category: 'text',
          action: 'login_signup',
        })
      }}
    >
      <Typography component='span' color={Colors.colorD7D9DB}>
        <Trans i18nKey='if_you_dont_have_account' />
      </Typography>
      <Typography component='span' marginLeft={2}>
        <Trans i18nKey='signup' />
      </Typography>
    </Button>
  )
}
