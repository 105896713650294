import { Box, Button, Link as MuiLink, Stack, Typography } from '@mui/material'
import NextLink from 'next/link'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { RhfPasswordField } from '~/components/forms/rhf/RhfPasswordField'
import { RhfTextField } from '~/components/forms/rhf/RhfTextField'
import { useMediaQuery } from '~/hooks/useMediaQuery'
import * as GA from '~/libs/ga'
import { Colors } from '~/libs/mui/theme'
import { isValidEmail, validatePassword } from '~/libs/validate'

export interface LoginFormInputs {
  email: string
  password: string
}

interface LoginFormProps {
  onSubmitLoginInfo: (inputs: LoginFormInputs) => Promise<void>
  defaultEmail?: string
}

export function LoginForm({ onSubmitLoginInfo, defaultEmail }: LoginFormProps): JSX.Element {
  const { isSm } = useMediaQuery()
  const { t } = useTranslation()
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<LoginFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      email: defaultEmail ?? '',
      password: '',
    },
  })

  const onSubmit = handleSubmit((inputs: LoginFormInputs) => {
    onSubmitLoginInfo(inputs)
  })

  const validateEmail = useCallback(
    (email: string) => (isValidEmail(email) ? undefined : t('please_enter_correct_email')),
    [t],
  )

  const verifyPassword = useCallback(
    (password: string) => (validatePassword(password) ? undefined : t('password_requirement')),
    [t],
  )

  return (
    <Box component='form' onSubmit={onSubmit}>
      <Stack spacing={isSm ? 3 : 1}>
        <RhfTextField
          name='email'
          type='email'
          label={t('email_address')}
          control={control}
          rules={{ required: t('please_enter_email'), validate: validateEmail }}
        />
        <RhfPasswordField
          name='password'
          label={t('password')}
          helperText={t('signup_password_note')}
          control={control}
          rules={{
            required: t('please_enter_password'),
            minLength: { value: 8, message: t('signup_password_note') },
            validate: verifyPassword,
          }}
        />
      </Stack>
      <Stack spacing={2}>
        <MuiLink
          ml={2}
          noWrap
          variant='button'
          component={NextLink}
          href={'/login/forgot_password'}
          underline='none'
          onClick={() => {
            GA.sendEvent({
              event: 'click',
              screen: 'login',
              category: 'text',
              action: 'login_password_forget',
            })
          }}
        >
          <Typography
            component='span'
            color={Colors.colorD7D9DB}
            sx={{
              textDecoration: 'underline',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '140%',
            }}
          >
            {/* パスワードをお忘れですか */}
            <Trans i18nKey='forgot_password' />
          </Typography>
        </MuiLink>
        <Button
          fullWidth
          type='submit'
          variant='contained'
          disabled={!isValid}
          sx={{ color: Colors.color23221F }}
        >
          {/*　ログイン */}
          <Trans i18nKey='login' />
        </Button>
      </Stack>
    </Box>
  )
}
