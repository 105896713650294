'use client'

import { Box } from '@mui/material'
import { Auth } from 'aws-amplify'
import { isAxiosError } from 'axios'
import { NextPage } from 'next'
import { useRouter } from 'next/navigation'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'
import { LoadingBackdrop } from '~/components/LoadingBackdrop'
import { CompanyInfoModalOpener } from '~/features/auth/components/CompanyInfoModalOpener'
import { useAddLocaleParams } from '~/features/i18n/hooks/useAddLocaleParams'
import { Login } from '~/features/login/components/Login'
import { LoginFormInputs } from '~/features/login/components/Login/LoginForm'
import { ProviderId } from '~/libs/auth/provider'
import { useAxios } from '~/libs/axios'
import * as GA from '~/libs/ga'
import { loginInfoState, userLoginState } from '~/recoil/auth'
import { verifyEmailState, verifyPasswordState } from '~/recoil/signup'
import { Profile } from '~/types/api/users'
import { PageWithHeader } from '../_components/PageWithHeader'

const LoginPage: NextPage<{}> = () => {
  const axios = useAxios()
  const { push } = useRouter()
  const { t } = useTranslation()
  const localeParams = useAddLocaleParams()
  const setUserLoginState = useSetRecoilState(userLoginState)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const setVerifyEmail = useSetRecoilState(verifyEmailState)
  const setVerifyPassword = useSetRecoilState(verifyPasswordState)
  const setLoginInfoState = useSetRecoilState(loginInfoState)

  const onSubmit = useCallback(
    async (inputs: LoginFormInputs) => {
      setVerifyEmail('')
      setVerifyPassword('')
      setError(undefined)
      setLoading(true)

      GA.sendEvent({
        event: 'click',
        screen: 'login',
        category: 'form',
        action: 'login_password',
      })

      try {
        await Auth.signOut()
      } catch (error) {}

      try {
        const res = await axios.post<{ require_reset: boolean }>(
          `/v2/password/require_reset?${localeParams}`,
          {
            email: inputs.email,
            no_create: true,
          },
        )
        if (res.data.require_reset) {
          const encodeEmail = encodeURIComponent(inputs.email)
          void push(`/login/reset_password?email=${encodeEmail}`)
          return
        }

        await Auth.signIn(inputs.email, inputs.password)
        setUserLoginState('signedIn')
        try {
          const session = await Auth.currentSession()
          const token = session.getAccessToken().getJwtToken()
          const res = await axios.get<Profile>(`/v2/user/edit`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })

          if (res.data.next_payment_date == undefined) {
            if (res.data.user_status == 'withdrawal_user') {
              void push('/profile/reactivate')
            } else if (res.data.payment_type == 'stripe') {
              void push('/profile/payment/edit')
            } else {
              void push('/profile/payment/new')
            }
            return
          }
        } catch (error) {
          console.error(error)
          if (isAxiosError(error)) {
            console.log(error.response)
            switch (error.response?.status) {
              case 300:
                setUserLoginState('noProfile')
                void push('/profile/new')
                return
              case 409:
                alert(t('incorrect_login'))
                await Auth.signOut()
                setUserLoginState('notSignedIn')
                return
              default:
                break
            }
          }
        }
        void push('/')
      } catch (error) {
        if (
          typeof error === 'object' &&
          error !== null &&
          'code' in error &&
          typeof error.code === 'string'
        ) {
          switch (error.code) {
            case 'NotAuthorizedException':
              axios
                .get('/v2/user/provider?email=' + inputs.email)
                .then(({ data }) => {
                  setLoginInfoState({ email: inputs.email, provider: data.provider })
                })
                .catch(() => {
                  setError(t('email_or_password_is_incorrect'))
                })
              setLoading(false)
              return
            case 'UserNotConfirmedException':
              // Cognitoにユーザーは作成されているが確認ステータスが未確認
              // コードを再送する
              await Auth.resendSignUp(inputs.email)
              setVerifyEmail(inputs.email)
              setVerifyPassword(inputs.password)
              void push('/signup/verify')
              return
            default:
              break
          }
        }
        console.error(error)
        setError(t('error_occurred'))
      } finally {
        setLoading(false)
      }
    },
    [
      push,
      setVerifyEmail,
      setVerifyPassword,
      t,
      axios,
      setUserLoginState,
      localeParams,
      setLoginInfoState,
    ],
  )

  const loginWithSocialAccount = useCallback(
    (provider: ProviderId) => {
      push('/unlink_sns')
    },
    [push],
  )

  return (
    <PageWithHeader isDisplayBrandLogoOnMobile mobileRightContent={<CompanyInfoModalOpener />}>
      <Box paddingTop={{ xs: 2, sm: 4 }} sx={{ height: '100%' }}>
        <LoadingBackdrop open={loading} />
        <Login
          errorMessage={error}
          onSubmitLoginInfo={onSubmit}
          onClickSocialLogin={loginWithSocialAccount}
        />
      </Box>
    </PageWithHeader>
  )
}

export default LoginPage
