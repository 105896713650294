import { Link as MuiLink, Typography } from '@mui/material'
import { default as NextLink } from 'next/link'
import { Trans } from 'react-i18next'
import { SvgAsset } from '~/components/SvgAsset'
import { URLConstants } from '~/libs/constants'
import * as GA from '~/libs/ga'
import { Colors } from '~/libs/mui/theme'

export function NoticeForFacebookLoginUserLink(): JSX.Element {
  return (
    <MuiLink
      component={NextLink}
      href={URLConstants.facebookLoginInfo}
      variant='button'
      target='_blank'
      underline='none'
      textAlign='center'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: {
          xs: '16px',
        },
      }}
      onClick={() => {
        GA.sendEvent({
          event: 'click',
          screen: 'login',
          category: 'text',
          action: 'login_notification_facebook',
        })
      }}
    >
      <SvgAsset asset='info' sx={{ width: 19, height: 19, marginRight: '8px' }} />
      <Typography
        component='span'
        color={Colors.colorD7D9DB}
        sx={{
          textDecoration: 'underline',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '140%',
        }}
      >
        <Trans i18nKey='for_use_facebook_login_user' />
      </Typography>
    </MuiLink>
  )
}
