export const isValidEmail = (email: string) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  )

/** 英小文字が含まれているかどうか */
export const hasLowerCaseLetters = (value: string) => /[a-z]/.test(value)

/** 英大文字が含まれているかどうか */
export const hasUpperCaseLetters = (value: string) => /[A-Z]/.test(value)

/** 数字が含まれているかどうか */
export const hasDigitLetters = (value: string) => /\d/.test(value)

export const validatePassword = (value: string) => {
  if (!hasLowerCaseLetters(value) || !hasUpperCaseLetters(value) || !hasDigitLetters(value)) {
    return false
  }
  return true
}
