import { envsafe, str, url } from 'envsafe'
import browserEnv from './browserEnv'

const serverEnv = {
  ...browserEnv,
  ...envsafe(
    {
      SERVER_COGNITO_CLIENT_ID: str({
        devDefault: '24rcmlse0leciaoq55f753u0t9',
      }),
      SERVER_COGNITO_CLIENT_SECRET: str({
        devDefault: 'm56jjuojekuerb9fkarteum7pn36424pkiv8corgjs5809tppvq',
      }),
      NEXTAUTH_URL: url({
        devDefault: 'http://localhost:3000',
      }),
      NEXTAUTH_SECRET: str({
        default: 'mM!MQm6Ig280',
      }),
    },
    { strict: true },
  ),
}

export default serverEnv
