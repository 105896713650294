import { ErrorOutline } from '@mui/icons-material'
import { Drawer, Hidden } from '@mui/material'
import { useRouter } from 'next/navigation'
import { useCallback, useState } from 'react'
import { URLConstants } from '~/libs/constants'
import { Colors } from '~/libs/mui/theme'
import { CompanyInfoModalContent } from './CompanyInfoModalContent'

export function CompanyInfoModalOpener(): JSX.Element {
  const { push } = useRouter()
  const [isOpen, setIsOpen] = useState(false)

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const redirectToContactForm = useCallback(() => {
    setIsOpen(false)
    void push('/setting/contact')
  }, [push])

  const redirectToCompanyHp = useCallback(() => {
    setIsOpen(false)
    // 異なるドメインページに飛ぶので、別タブで開く
    window.open(URLConstants.homepage, '_blank')
  }, [])

  return (
    <>
      <ErrorOutline
        sx={{
          fontSize: '2rem',
          color: Colors.color5A5957,
        }}
        onClick={() => setIsOpen(true)}
      />
      {/* モーダルコンテンツ */}
      <Hidden smUp>
        <Drawer anchor='bottom' open={isOpen} onClose={onClose}>
          <CompanyInfoModalContent
            onClose={onClose}
            redirectToContactForm={redirectToContactForm}
            redirectToCompanyHp={redirectToCompanyHp}
          />
        </Drawer>
      </Hidden>
    </>
  )
}
