import { OAuthConfig } from 'next-auth/providers'
import { awsExports } from '~/aws'
import serverEnv from '~/libs/env/serverEnv'

export type ProviderId = 'Google' | 'Apple' | 'Twitter'

interface IdP {
  id: ProviderId
  identity_provider: string
}

export const idps: IdP[] = [
  {
    id: 'Google',
    identity_provider: 'Google',
  },
  {
    id: 'Apple',
    identity_provider: 'SignInWithApple',
  },
  {
    id: 'Twitter',
    identity_provider: 'Twitter',
  },
]

const makeProvider = (idp: IdP): OAuthConfig<{ sub: string; email: string }> => {
  return {
    id: idp.id,
    name: idp.id,
    type: 'oauth',
    issuer: `https://cognito-idp.${awsExports.aws_cognito_region}.amazonaws.com/${serverEnv.SERVER_COGNITO_CLIENT_ID}`,
    authorization: {
      url: `https://${awsExports.oauth.domain}/oauth2/authorize`,
      params: {
        identity_provider: idp.identity_provider,
        redirect_uri: `${serverEnv.NEXTAUTH_URL}/api/auth/callback/${idp.id}`,
        response_type: 'CODE',
        client_id: serverEnv.SERVER_COGNITO_CLIENT_ID,
        scope: 'email openid',
      },
    },
    token: {
      params: {
        grant_type: 'authorization_code',
        client_id: serverEnv.SERVER_COGNITO_CLIENT_ID,
        client_secret: serverEnv.SERVER_COGNITO_CLIENT_SECRET,
        scope: 'email openid',
        redirect_uri: `${serverEnv.NEXTAUTH_URL}/api/auth/callback/${idp.id}`,
      },
      async request(context) {
        const formBody = Object.entries(context.params)
          .filter(([_, v]) => typeof v === 'string')
          .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v as string)}`)
          .join('&')
        const response = await fetch(`https://${awsExports.oauth.domain}/oauth2/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body: formBody,
        })
        const tokens = await response.json()
        return { tokens }
      },
    },
    userinfo: {
      url: `https://${awsExports.oauth.domain}/oauth2/userInfo`,
      async request(context) {
        const response = await fetch(`https://${awsExports.oauth.domain}/oauth2/userInfo`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${context.tokens.access_token}`,
          },
        })
        return response.json()
      },
    },
    profileUrl: `https://${awsExports.oauth.domain}/oauth2/userInfo`,
    profile(profile) {
      return {
        id: profile.sub,
        email: profile.email,
      }
    },
    clientId: serverEnv.SERVER_COGNITO_CLIENT_ID,
    clientSecret: serverEnv.SERVER_COGNITO_CLIENT_SECRET,
  }
}

export const providers = idps.map(makeProvider)
