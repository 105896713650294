import { Container, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PublicHeader } from '~/components/PublicHeader'

export const PageWithHeader = ({
  titleKey,
  mobileRightContent,
  isDisplayBrandLogoOnMobile = false,
  children,
}: {
  titleKey?: 'reset_password' | 'account_recovery' | 'maintenance_mode'
  mobileRightContent?: React.ReactNode
  isDisplayBrandLogoOnMobile?: boolean
  children: React.ReactNode
}) => {
  const { t } = useTranslation()
  const title = titleKey ? t(titleKey) : ''

  return (
    <>
      <PublicHeader
        title={title}
        isDisplayBrandLogoOnMobile={isDisplayBrandLogoOnMobile}
        mobileRightContent={mobileRightContent}
      />
      <Container maxWidth='sm' disableGutters>
        <Paper
          sx={{
            paddingTop: '70px',
            paddingBottom: 4,
            paddingX: { xs: 4, sm: 8 },
            height: '100vh',
          }}
        >
          {children}
        </Paper>
      </Container>
    </>
  )
}
