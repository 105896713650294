import { Link as MuiLink, Typography } from '@mui/material'
import { default as NextLink } from 'next/link'
import { Trans } from 'react-i18next'
import { SvgAsset } from '~/components/SvgAsset'
import { Colors } from '~/libs/mui/theme'

export function SNSUnlinkLink(): JSX.Element {
  return (
    <MuiLink
      component={NextLink}
      href='/unlink_sns'
      variant='button'
      underline='none'
      textAlign='center'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: {
          xs: '16px',
        },
      }}
    >
      <SvgAsset asset='info' sx={{ width: 19, height: 19, marginRight: '8px' }} />
      <Typography
        component='span'
        color={Colors.colorD7D9DB}
        sx={{
          textDecoration: 'underline',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '140%',
        }}
      >
        <Trans i18nKey='for_users_using_social_media_login' />
      </Typography>
    </MuiLink>
  )
}
