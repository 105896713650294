import { KeyboardArrowRight } from '@mui/icons-material'
import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import NextLink from 'next/link'
import { useCallback, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { useMediaQuery } from '~/hooks/useMediaQuery'
import { idps, ProviderId } from '~/libs/auth/provider'
import { Colors } from '~/libs/mui/theme'
import { loginInfoState } from '~/recoil/auth'
import {
  ForHasNotAccountLink,
  LoginFAQLink,
  NoticeForFacebookLoginUserLink,
  SNSUnlinkLink,
} from './AdditionalContents'
import { LoginForm, LoginFormInputs } from './LoginForm'

interface LoginProps {
  errorMessage?: string
  onSubmitLoginInfo: (inputs: LoginFormInputs) => Promise<void>
  onClickSocialLogin: (provider: ProviderId) => void
}

export function Login({
  errorMessage = undefined,
  onSubmitLoginInfo,
  onClickSocialLogin,
}: LoginProps): JSX.Element {
  const { isSm } = useMediaQuery()
  const [loginInfo, setLoginInfoState] = useRecoilState(loginInfoState)
  const [closedSuggestion, setClosedSuggestion] = useState(false)
  const idp = useMemo(
    () => idps.find(({ identity_provider }) => identity_provider === loginInfo.provider),
    [loginInfo.provider],
  )

  const suggestLogin = useCallback(() => {
    if (loginInfo.provider === 'Cognito') {
      setClosedSuggestion(true)
    }

    if (idp) {
      onClickSocialLogin(idp.id)
    }
  }, [loginInfo.provider, idp, onClickSocialLogin])

  if ((loginInfo.provider === 'Cognito' || idp) && !closedSuggestion) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Stack spacing={isSm ? 4 : 5}>
          {isSm && (
            <Typography variant='h1' color={Colors.yellow} textAlign='center'>
              <Trans i18nKey='login' />
            </Typography>
          )}
          <Stack spacing={1}>
            <Typography
              variant='h3'
              fontWeight={700}
              fontSize={{ xs: 16, sm: 18 }}
              lineHeight='22.5px'
              textAlign='center'
            >
              <Trans i18nKey='there_might_be_a_different_method_of_logging_in' />
            </Typography>
            <Typography fontWeight={400} fontSize={14} textAlign='center'>
              <Trans i18nKey='you_can_select_an_account_that_has_previously_logged_in_with_this_email_address' />
            </Typography>
          </Stack>
          <Button
            variant='text'
            sx={{
              color: Colors.colorAAA8A1,
              backgroundColor: Colors.color121213,
              borderBottom: '1px solid #1A1A18',
            }}
            endIcon={<KeyboardArrowRight />}
            onClick={suggestLogin}
          >
            <Stack spacing={1} alignItems='flex-start' width='100%'>
              <Typography color='#FAF9F5'>{loginInfo.email}</Typography>
              {loginInfo.provider === 'Cognito' ? (
                <Typography>
                  <Trans i18nKey='log_in_with_email_address' />
                </Typography>
              ) : idp?.id === 'Google' ? (
                <Typography>
                  <Trans i18nKey='log_in_with_google' />
                </Typography>
              ) : idp?.id === 'Apple' ? (
                <Typography>
                  <Trans i18nKey='log_in_with_apple' />
                </Typography>
              ) : idp?.id === 'Twitter' ? (
                <Typography>
                  <Trans i18nKey='log_in_with_x' />
                </Typography>
              ) : null}
            </Stack>
          </Button>
          <Button
            variant='text'
            component={NextLink}
            href=''
            onClick={() => setLoginInfoState({ email: '', provider: '' })}
          >
            <Trans i18nKey='log_in_with_a_different_account' />
          </Button>
        </Stack>
        <Stack spacing={isSm ? 4 : 5}>
          <Stack spacing={2}>
            <Typography textAlign='center'>
              <Trans i18nKey='signup_for_one_week_free_trial' />
            </Typography>
            <ForHasNotAccountLink />
          </Stack>
          <LoginFAQLink />
          <NoticeForFacebookLoginUserLink />
        </Stack>
      </Box>
    )
  }

  return (
    <>
      <Stack spacing={isSm ? 4 : 5}>
        {isSm && (
          <Typography variant='h1' color={Colors.yellow} textAlign='center'>
            <Trans i18nKey='login' />
          </Typography>
        )}
        {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
        <LoginForm
          onSubmitLoginInfo={onSubmitLoginInfo}
          defaultEmail={loginInfo.provider === 'Cognito' ? loginInfo.email : ''}
        />
        <SNSUnlinkLink />
      </Stack>
      <Stack spacing={isSm ? 4 : 5} mt={8}>
        <Stack spacing={2}>
          <Typography textAlign='center'>
            <Trans i18nKey='signup_for_one_week_free_trial' />
          </Typography>
          <ForHasNotAccountLink />
        </Stack>
        <LoginFAQLink />
      </Stack>
    </>
  )
}
