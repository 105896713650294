import { AppBar } from '@mui/material'
import { FC, ReactNode } from 'react'
import { SvgAsset } from '~/components/SvgAsset'
import { useMediaQuery } from '~/hooks/useMediaQuery'
import { PublicHeaderMobileContent } from './PublicHeaderMobileContent'

export interface PublicHeaderProps {
  /** モバイル時に表示するタイトル */
  title?: string
  /** モバイル時にもブランドロゴを表示するかどうか */
  isDisplayBrandLogoOnMobile?: boolean
  /** モバイルサイズの時に右側に表示するコンテンツ */
  mobileRightContent?: ReactNode
}

export const PublicHeader: FC<PublicHeaderProps> = (props) => {
  const { isSm } = useMediaQuery()

  return (
    <AppBar
      elevation={0}
      sx={{
        height: '70px',
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {isSm ? (
        <SvgAsset asset='logo_with_text' sx={{ width: 114, height: 30 }} />
      ) : (
        <PublicHeaderMobileContent {...props} />
      )}
    </AppBar>
  )
}
